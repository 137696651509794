.references-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 80px;
}

.references-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  max-width: 1440px;
  align-items: flex-start;
  flex-direction: column;
}

.references-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-style: hidden;
  border-width: 0px;
}

.references-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-style: hidden;
  border-width: 0px;
  margin: 30px 0px;
}

.references-container35 {
  top: 0;
  flex: 0 0 auto;
  left: 0;
  width: 96px;
  height: 100%;
  display: flex;
  z-index: 3;
  position: absolute;
  align-items: flex-start;
  /* background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 1%,
    rgb(255, 255, 255) 99%
  ); */
}

.references-container36 {
  top: 0;
  flex: 0 0 auto;
  right: 0;
  width: 96px;
  height: 100%;
  display: flex;
  z-index: 3;
  position: absolute;
  align-items: flex-start;
  /* background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 1%,
    rgb(255, 255, 255) 100%
  ); */
}

.references-image {
  width: 150px;
  height: 100px;
  object-fit: contain;
  object-position: center center;
  opacity: 0.5;
}
.references-image-colorful {
  width: 100%;
  height: 80px;
  object-fit: contain;
  object-position: center center;
  opacity: 1;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
}

.marquee-content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 1440px;
}

.marquee-animation1 {
  animation: scroll 40s linear infinite;
}

.marquee-animation2 {
  animation: scroll 35s linear infinite;
}

.marquee-animation3 {
  animation: scroll 45s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 800px) {
  .marquee-content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 600px;
  }

  .references-image {
    width: 80px;
    height: 30px;
  }
  .references-image-colorful {
    width: 80px;
    height: 30px;
  }
  .references-container02 {
    height: 40px;
  }
  .references-container01 {
    max-width: 800px;
  }
  .references-container03 {
    height: 40px;
  }
}
