.footer_container {
  border-top: 2px solid var(--dl-theme-color);
  display: flex;
  justify-content: center;
  margin-top: 0;
  width: 100%;
  background-color: #454f5b;
}
.footer_info {
  display: flex;
  justify-content: space-around;
  width: 75%;
  max-width: 1440px;
}
.footer_icon {
  width: 24px;
  height: 24px;
}
.footer_content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  row-gap: 15px;
  width: 50%;
  align-items: center;
}
.footer_contact {
  display: flex;
  column-gap: 5px;
}
.footer_number:hover {
  opacity: 0.6;
  color: var(--dl-nav-home);
}
.footer_number {
  font-size: 14px;
  text-decoration: none;
  color: #b4ae9b;
}
.footer_logo {
  padding-bottom: 10px;
}
.column {
  display: grid;
  row-gap: 10px;
  padding-bottom: 10px;
}
@media (max-width: 991px) {
  .footer_info {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .footer_content {
    display: block;
    padding-top: 10px;
  }
  .footer_contact {
    padding-bottom: 5px;
  }
  .footer_info {
    width: 100%;
    align-items: center;
  }
  .footer_logo {
    height: 120px;
    width: 120px;
  }
  .footer_number {
    font-size: 10px;
  }
}
