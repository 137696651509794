.what_we_do_page {
  padding-top: 80px;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headline {
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
  padding-bottom: 50px;
}
.description {
  font-size: 20px;
  font-weight: 300;
  line-height: 29px;
  width: 95%;
  padding-bottom: 60px;
}
.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.custom_overlay {
  background-color: rgba(111, 105, 105, 0.8);
  max-height: 240px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 2px;
  font-size: 90%;
}
.capacity {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.responsive_image {
  max-width: 100%;
  height: auto;
}
.image_container {
  position: relative;
}

/* Image styling */
.image_container img {
  width: 100%;
  height: auto;
  display: block;
  min-height: 125px;
}

/* Gradient overlay */
.image_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    154.49deg,
    rgba(0, 0, 0, 0.5) 5%,
    rgba(0, 0, 0, 0) 100%
  );
  /* Adjust the gradient values and colors as needed */
}
@media (max-width: 767px) {
  .what_we_do_page {
    padding-top: 80px;
  }
  .headline {
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    padding-bottom: 30px;
  }
  .description {
    font-size: 17px;
    line-height: 20px;
    padding-bottom: 30px;
  }
  .capacity_image {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
}
