.header_container {
  position: fixed;
  width: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 25%;
  color: var(--dl-nav-home);
  box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  -webkit-box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  -moz-box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  z-index: 999;
}
.logo {
  opacity: 0.85;
  padding-top: 10px;
}
.name {
  font-size: 50px;
  line-height: 40px;
}
.logo_name {
  display: flex;
  align-items: center;
  float: left;
}
.navigation_container {
  float: right;
  height: 100%;
  display: table;
}
.navigation_menu {
  float: right;
  height: 100%;
  padding: 0 10px;
  padding-right: 0;
  vertical-align: middle;
  display: table-cell;
}
ul {
  list-style: none;
  display: table;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
}
li {
  position: relative;
  float: left;
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 26px;
  cursor: pointer;
  line-height: 30px;
  color: var(--dl-nav-home);
}
li:hover {
  opacity: 0.6;
  color: var(--dl-nav-home);
}
.mobile_list {
  display: grid;
  padding: 30px;
  row-gap: 35px;
  height: auto;
  width: 100%;
  z-index: 990;
  background-color: var(--dl-background-color);
  box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  animation: header_slideIn__T0aKr 0.5s ease;
  position: fixed;
  top: 60px;
}
.flag::after {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 10px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
  font-size: 16px;
  color: #333;
  margin-left: 5px;
}
.li_flag::after {
  content: "\00BB"; /* Unicode character for right-pointing double angle quotation mark (chevron) */
  font-size: 16px; /* Adjust the font size as needed */
  color: #333; /* Adjust the color as needed */
  margin-left: auto;
}
@keyframes slideIn {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}
.top_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(217 217 217 / 60%);
  color: var(--dl-nav-home);
  box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  -webkit-box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  -moz-box-shadow: -1px 8px 5px -5px rgba(59, 61, 63, 0.52);
  height: 100px;
  padding: 1em;
  z-index: 999;
  position: fixed;
  width: 100%;
}
.menu_button_container {
  display: flex;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu_button,
.menu_button::before,
.menu_button::after {
  display: block;
  background-color: var(--dl-nav-home-light);
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}
.menu_button::before {
  content: "";
  margin-top: -8px;
}
.menu_button::after {
  content: "";
  margin-top: 8px;
}
@media (max-width: 1370px) {
  .header_container {
    gap: 15%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name {
    font-size: 45px;
    line-height: 35px;
  }
  li {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 1250px) {
  .header_container {
    gap: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name {
    font-size: 45px;
    line-height: 35px;
  }
  li {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 991px) {
  .header_container {
    gap: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name {
    font-size: 45px;
    line-height: 35px;
  }
  li {
    font-size: 24px;
    font-weight: 600;
  }
}
@media (max-width: 850px) {
  .header_container {
    gap: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name {
    font-size: 45px;
    line-height: 35px;
  }
}
@media (max-width: 767px) {
  .header_container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name {
    font-size: 25px;
    line-height: 20px;
  }
  .top_nav {
    height: 80px;
  }
  .logo {
    width: 50px;
  }
  li {
    font-weight: 600;
    color: var(--dl-nav-home-light);
  }
}
@media (max-width: 479px) {
  .header_container {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .name {
    font-size: 24px;
    line-height: 16px;
    font-weight: 700;
  }
  li {
    font-size: 18px;
    line-height: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .logo {
    width: 50px;
  }
}
