.home {
  padding-top: calc(100vh - 68vh);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: linear-gradient(
      154.49deg,
      rgba(0, 0, 0, 0.5) 5%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(../../home.webp);
  background-size: cover;
}
.year {
  font-size: 350px;
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  /* margin-bottom: 15%; */
  margin-bottom: 10rem;
  max-width: 100%;
}
.subtitle {
  font-size: 100px;
  line-height: 40px;
  margin-bottom: 5%;
}
.title {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 2%;
}
.button {
  border: solid 3px;
  border-radius: 30px;
  padding: 20px 30px;
  width: fit-content;
  font-size: 36px;
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  margin-bottom: 15px;
}
.button:hover {
  opacity: 0.7;
  color: #e4c666;
}
.container {
  color: white;
  width: 100%;
}
.second_container {
  padding-top: 4%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.second_subtitle {
  color: var(--dl-color);
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  width: 100%;
}
.quality_row {
  display: flex;
  padding-top: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: 5%;
  flex-direction: row;
  max-width: 1440px;
}
.quality_column {
  display: block;
  width: 100%;
}
.icon {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
.column_title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #eeeeee;
}
.column_description {
  color: #eeeeee;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 50px;
  padding-right: 15px;
  text-align: start;
}
.full_width_image_container {
  text-align: center;
  display: inline-flex;
  padding-bottom: 70px;
  width: 90%;
  justify-content: center;
  column-gap: 5%;
  padding-top: 50px;
  max-width: 1440px;
}
.full_width_image {
  width: 100%;
  max-height: 400px;
}
.full_width_image_text {
  text-align: start;
  display: grid;
  align-items: start;
  color: var(--dl-color);
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}
.intro {
  color: var(--dl-color);
  padding-top: 20px;
  max-width: 650px;
}
.colored_container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  column-gap: 5%;
  flex-direction: row;
  background: #8e8b8bc7;
  border-radius: 18px;
  padding: 20px;
}
@media (max-width: 1440px) {
  .quality_row {
    width: 95%;
  }
  .colored_container {
    width: 100%;
  }
  .full_width_image_container {
    width: 95%;
  }
}
@media (max-width: 991px) {
  .year {
    font-size: 250px;
  }
  .subtitle {
    font-size: 70px;
  }
  .title {
    font-size: 32px;
  }
  .button {
    font-size: 28px;
  }
  .icon {
    width: 180px;
    height: 180px;
  }
  .quality_row {
    width: 95%;
  }
  .full_width_image_container {
    width: 95%;
  }
}
@media (max-width: 830px) {
  .full_width_image_container {
    display: inline-grid;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding-top: 20px;
  }
  .full_width_image {
    width: 100%;
  }
  .full_width_image_text {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 2%;
  }
}
@media (max-width: 767px) {
  .home {
    padding-top: 150px;
    height: 100vh;
  }
  .year {
    font-size: 180px;
  }
  .subtitle {
    font-size: 50px;
  }
  .title {
    font-size: 28px;
  }
  .button {
    font-size: 24px;
  }
  .second_subtitle {
    font-size: 20px;
    line-height: 34px;
  }
  .quality_column {
    display: contents;
  }
  .column_title {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 10px;
    justify-content: flex-start;
    display: flex;
  }
  .column_description {
    font-size: 17px;
    line-height: 20px;
    justify-content: flex-start;
    display: flex;
    padding-bottom: 20px;
  }
  .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  .quality_row {
    padding-top: 40px;
    align-items: self-start;
    flex-direction: column;
  }
  .colored_container {
    align-items: self-start;
    flex-direction: column;
  }
  .second_container {
    justify-content: center;
    align-items: center;
  }
  .full_width_image_text {
    color: var(--dl-color);
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5%;
    text-align: start;
    display: grid;
    row-gap: 3%;
  }
  .full_width_image_container {
    display: inline-grid;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding-top: 20px;
  }
  .full_width_image {
    width: 100%;
    max-height: 350px;
  }
}
@media (max-width: 479px) {
  .year {
    font-size: 120px;
    margin-bottom: 15%;
  }
  .subtitle {
    font-size: 40px;
    margin-bottom: 10%;
  }
  .title {
    font-size: 20px;
  }
  .button {
    font-size: 16px;
    padding: 10px 15px;
  }
}
