body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

:root {
  --dl-color: #454f5b;
  --dl-background-color: #d9d9d9;
  --dl-orange: #ffbf47;
  --dl-pink: #f98c80;
  --dl-yellow: #ffe87a;
  --dl-blue: #409995;
  --dl-blue-light: #8bc1be;
  --dl-font-size: 24px;
  --dl-line-height: 32px;
  --dl-font-weight: 400;
  --dl-gold: #e4c666;
  --dl-theme-color: #e2cc64;
  --dl-nav-color: #ccc9bd;
  --dl-nav-home-light: #302f2f;
  --dl-nav-home: #1b1a1a;
}
h2 {
  margin-top: 0;
}
h1 {
  margin-top: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
