.map_container {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
.two_column_text {
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  padding: 40px;
  padding-bottom: 4%;
  text-align: start;
  justify-content: center;
  width: 100%;
}
.text {
  font-weight: 700;
}
.link {
  text-decoration: none;
  color: var(--dl-text-color);
}
@media (max-width: 991px) {
  .two_column_text {
    padding-top: 140px;
    padding-bottom: 10%;
    column-gap: 5%;
  }
  .map_container {
    margin-top: 80px;
    min-height: 82vh;
  }
  .text {
    font-size: 17px;
    font-weight: 500;
  }
}
@media (max-width: 767px) {
  .two_column_text {
    flex-direction: column;
    padding: 15px;
    column-gap: 0;
  }
  .map_container {
    margin-top: 80px;
  }
  .text {
    font-size: 17px;
    font-weight: 500;
    width: 95%;
  }
}
